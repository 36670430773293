import { Link } from "gatsby"
import React from "react"
import { Component } from "react"
import { Col, Container, Row } from "react-bootstrap"
import WistiaEmbed from "../global/wistia_embed"
import ButtonIcon from "../icon/buttonIcon"

class LvpHeader extends Component {
  render() {
    return (
      <div className="main-yellow-bg">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} className="text-center">
              <h1>The Top Live Video Production studio in India</h1>
              <div className="video-wrapper">
                <WistiaEmbed hashedId="npa7a21lth"
                />
                <span>Explainer Videos are our thing.</span>
              </div>
              <Row className="intro">
                <p>
                  Your company needs to be promoted with a live action video and
                  you know it. We create professional videos for your product
                  demos, creative presentations, corporate films & commercial
                  ads.We are the go-to-go crew across India.
                </p>
                <Col sm={6} className="d-flex justify-content-sm-end justify-content-center">
                  <Link to="#contact" class="btn wowaction-btn revert">
                    <ButtonIcon />
                    <span>Get Your video</span>
                  </Link>
                </Col>
                <Col sm={6} className="d-flex justify-content-sm-start justify-content-center">
                  <Link to="#service" class="btn wowaction-btn revert">
                    <ButtonIcon />
                    <span>Services</span>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default LvpHeader
