import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const LvpPricing = () => {
  return (
    <section className="lvp-pricing">
      <Container>
        <Row>
          <Col md={12} className="text-center">
            <h2 className="sub-title">Pricing</h2>
            <p className="sub-desc">
              What do you charge for your live video services? The rate of the
              project will depend on a lot of factors like the resources,
              effort, time and further cost to be put in it. Our usual project
              pricing could come anywhere around <strong>Rs 1 Lakh to Rs 30 Lakh</strong>.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default LvpPricing
