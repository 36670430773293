import React from 'react'
import SeoHead from '../../components/global/seoHead'
import Layout from '../../components/global/layout'
import Seo from '../../components/global/seo'
import ScrollIndicationHeightZero from '../../components/global/scrollIndicationHeightZero'
import LvpHeader from '../../components/live-video-production/lvpHeader'
import CompanyLogo from '../../components/global/companyLogo'
import LvpPortfolio from '../../components/live-video-production/lvpPortfolio'
import LvpService from '../../components/live-video-production/lvpService'
import LvpPricing from '../../components/live-video-production/lvpPricing'
import HomeForm from '../../components/home/homeForm'
import DirectContact from '../../components/global/directContact'

import liveVideosFeraturedImage from "../../../static/home/wowmakers.jpeg"
import '../../sass/pages/live-video-production.scss'


export const Head = () => (
  <>
    <SeoHead
      ogImage={liveVideosFeraturedImage}
      title="The Top Live Video Production House In Bangalore, India"
      description="Looking to hire the best live action video company in Bangalore? Get your corporate video at the most affordable price and top quality."
    />
  </> 
)

const LiveVideoProduction = () => {
    return(
        <Layout>
            <Seo
                bclass="live-video-production"
                bid="live-video-production"
             />
            <ScrollIndicationHeightZero />
            <LvpHeader />
            <CompanyLogo />
            <LvpPortfolio />
            <LvpService />
            <LvpPricing />
            <HomeForm page="live-video"/>
            <DirectContact />
        </Layout>
    )
}

export default LiveVideoProduction