import React from "react"
import LiveActionService from "./liveActionService"
import ProductDemoService from "./productDemoService"
import TvService from "./tvService"
import { Col, Container, Row } from "react-bootstrap"

const LvpService = () => {
  return (
    <section className="lvp-service" id="service">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title">
              Corporate Video Services That We Offer
            </h2>
            <p className="sub-desc">
              Here are the types of videos that we produce.
            </p>
          </Col>
          <Col lg={6} className="wrapper-main">
            <div className="service-details">
              <h3>Live Action</h3>
              <p>
                What's a better way than using a live shot video to explain your
                complex stuff? Let's produce a professional corporate video for
                your business.
              </p>
            </div>
          </Col>
          <Col lg={6} className="wrapper-main">
              <LiveActionService />
          </Col>
          <Col lg={6} className="wrapper-main">
            <ProductDemoService />
          </Col>

          <Col lg={6} className="wrapper-main">
            <div className="service-details">
              <h3>Product Demos</h3>
              <p>
                Do you need to promote your software/app with a sexy demo video?
                Let’s make your product look compelling with a live action
                video.
              </p>
            </div>
          </Col>

          <Col lg={6} className="wrapper-main">
            <div className="service-details">
              <h3>Testimonial Videos</h3>
              <p>
                You know your potential customers would love to see some actual
                social proof before buying. Get yourself a testimonial video
                from your clients to increase conversions.
              </p>
            </div>
          </Col>
          <Col lg={6} className="wrapper-main">
            <TvService />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default LvpService
