import React,{useState} from 'react'
import { StaticImage } from "gatsby-plugin-image"
import ModalVideo from 'react-modal-video'
import "../../sass/global/modal-video.scss"

const LiveActionService = () => {
    const [isOpen, setOpen] = useState(false)

	return (
        <React.Fragment>
			<ModalVideo channel="youtube" autoplay isOpen={isOpen} videoId="0VWQ33uCf38" onClose={() => setOpen(false)} />

                  <button className="videowrapp" onClick={() => setOpen(true)}>                 
                    <div className="video-box">
                        <StaticImage
                            src="../../images/live-video-production/live-action.jpeg"
                            placeholder="blurred"
                            width={540}
                            height={250}
                            alt="Live action video service"
                        />
                    </div>                   
                  </button>
		</React.Fragment>
	)
}

export default LiveActionService
